import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, map, of } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(public authService: AuthService, public router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | UrlTree | boolean {

    return new Observable<boolean>(obs => {
      this.authService.authState().subscribe((user) => {
        if (user) {
          obs.next(true);;
        }
        else {
          this.router.navigate(['account/sign-in']);
          obs.next(false);
        }
      });
    });
  }
}
