import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';

import { APP_ROUTES } from './app.routes';
import { Firestore, connectFirestoreEmulator, getFirestore, initializeFirestore, provideFirestore } from '@angular/fire/firestore';
import { environment } from '../environments/environment';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { connectStorageEmulator, getStorage, provideStorage } from '@angular/fire/storage';
import { connectFunctionsEmulator, getFunctions, provideFunctions } from '@angular/fire/functions';
import { provideHttpClient, withFetch, withJsonpSupport } from '@angular/common/http';
import { connectAuthEmulator, getAuth, provideAuth } from '@angular/fire/auth';
import { provideQuillConfig } from 'ngx-quill/config';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(APP_ROUTES), 
    //provideClientHydration(),
    provideHttpClient(withJsonpSupport(), withFetch()),
    importProvidersFrom(provideFirebaseApp(() => initializeApp(environment.firebase))),
    provideQuillConfig({
      modules: {
        //syntax: true,
        toolbar:  [['bold', 'italic', 'underline'],[{ 'header': [1, 2, 3, false] }],[{ 'list': 'ordered'}, { 'list': 'bullet' }],[{ 'indent': '-1'}, { 'indent': '+1' }],  ['clean'], ]
      }
    }),
    // importProvidersFrom(provideAppCheck(() => {

    //   let appCheck: AppCheck;

    //   if (!environment.useEmulators) {
    //     console.log("Init appcheck");
    //     console.log(environment.reCAPTCHASiteKey);
    //     appCheck = initializeAppCheck(getApp(), {
    //       provider: new ReCaptchaV3Provider(environment.reCAPTCHASiteKey),
    //       isTokenAutoRefreshEnabled: true
    //     })
    //   }
    //   else{
    //     appCheck = initializeAppCheck(getApp(), {
    //       provider: new ReCaptchaV3Provider(environment.appCheckDebugToken),
    //       isTokenAutoRefreshEnabled: true
    //     })
    //   }

    //   return appCheck;
    // })),
    importProvidersFrom(provideFirestore(() => getFirestore(environment.firebase.databaseName))),
    // importProvidersFrom(provideFirestore(() => {
    //   let firestore: Firestore;
    //   if (environment.useEmulators) {
    //     firestore = initializeFirestore(getApp(), {
    //       experimentalForceLongPolling: true
    //     });
    //     connectFirestoreEmulator(firestore, 'localhost', 8080);
    //   }
    //   else {
    //     firestore = initializeFirestore(getApp(), {
    //       experimentalForceLongPolling: false
    //     }, environment.firebase.databaseName);
    //   }
    //   return firestore;
    // })),
    importProvidersFrom(provideStorage(() => {
      const storage = getStorage(getApp());
      if (environment.useStorageEmulator) {
        connectStorageEmulator(storage, '127.0.0.1', 9199);
      }
      return storage;
    })),
    importProvidersFrom(provideFunctions(() => {
      const funcs = getFunctions(getApp(), environment.firebase.region);
      if (environment.useFunctionEmulator) {
        connectFunctionsEmulator(funcs, '127.0.0.1', 5001);
      }
      return funcs;
    })),
    importProvidersFrom(provideAuth(() => {
      const auth = getAuth(getApp());
      if (environment.useAuthEmulator) {
        connectAuthEmulator(auth, 'http://127.0.0.1:9099', {
          disableWarnings: true
        });
      }
      return auth;
    })),
  ]
};
