/// <reference types="@angular/localize" />

import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';
import { appConfig } from './app/app.config';

if (!environment.production) {
  console.log("Adding firebase AppCheck debug token");
  (<any>window).FIREBASE_APPCHECK_DEBUG_TOKEN = environment.appCheckDebugToken;
}

bootstrapApplication(AppComponent, appConfig).catch((err:any) => console.error(err));