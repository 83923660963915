import { CreateEventComponent } from './dashboard/organisation/plan/components/create-event/create-event.component';
import { Routes } from '@angular/router';
import { AuthGuard } from './shared/guards/auth.guard';
import { SystemAdminGuard } from './shared/guards/system-admin.guard';

export const APP_ROUTES: Routes = [
    {
        path: '',
        redirectTo: 'select-organisation',
        pathMatch: 'full'
    },    
    {
        path: 'account',
        loadChildren: () => import('./account/account.routes').then((m) => m.ACCOUNT_ROUTES),
        // loadComponent: () =>
        //     import('./administer/components/administration-dashboard/administration-dashboard.component').then((m) => m.AdministrationDashboardComponent)
    },    
    {
        path: 'admin',
        loadChildren: () => import('./dashboard/administer/administer.routes').then((m)=> m.ADMINISTER_ROUTES),
        loadComponent: () =>
            import('./dashboard/administer/administer-layout.component').then((m) => m.AdministerLayoutComponent),
        canActivate: [SystemAdminGuard]
    },
    {
        path: 'organisation',
        loadChildren: () => import('./dashboard//organisation/organisation.routes').then((m)=> m.ORGANISATION_ROUTES),
        loadComponent: () =>
            import('./dashboard/organisation/organisation-layout.component').then((m) => m.OrganisationLayoutComponent),
        canActivate: [AuthGuard]
    }, 
    {
        path: 'select-organisation',
        loadComponent: () =>
            import('./select-organisation/select-organisation.component').then((m) => m.SelectOrganisationComponent),
        canActivate: [AuthGuard]
    },
    {
        path: 'create-organisation',
        loadComponent: () =>
            import('./create-organisation/create-organisation.component').then((m) => m.CreateOrganisationComponent),
        canActivate: [AuthGuard]
    }
];