import { CanActivateFn, UrlTree } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { inject, Pipe } from '@angular/core';
import { Observable, from, map, mergeMap, of } from 'rxjs';

export const SystemAdminGuard: CanActivateFn = (route, state):
  Observable<boolean | UrlTree>
  | Promise<boolean | UrlTree>
  | boolean
  | UrlTree => {

  const authService: AuthService = inject(AuthService);

  return authService.CurrentUser().pipe(
    mergeMap(user => from(user.getIdTokenResult(true))),
    map(idToken => {
      const isAdminClaim = idToken['claims']['systemAdministrator'];
      return isAdminClaim != undefined && isAdminClaim == true;
    }));
 
};

